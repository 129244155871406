@import url("https://fonts.googleapis.com/css?family=Varela+Round");

// Purple Stuff I had before:
// $color-bg: #0f0f0f;
// $color-primary: #9067c6;
// $color-secondary: #8d86c9;
// $color-subtle: #c1c1c1;
// $color-fg: #fffaff;

// Horizon
// $color-bg: #1c1e26;
// $color-primary: #b877db;
// $color-secondary: #25b2bc;
// $color-tertiary: #e95678;
// $color-subtle: #f09383;
// $color-fg: #fac29a;

// Original:
$color-bg: #141414;
$color-subtle: #1f2833;
$color-fg: #effafb;
$color-primary: #e85d75;
$color-secondary: #7845b6;
$color-tertiary: #50c9ce;

//General global classes
.centre {
    text-align: center;
}

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
    letter-spacing: 1.1px;
}

body,
html {
    width: 100%;
    height: 100%;
    background: $color-bg;
    // background: linear-gradient(5deg, $color-bg, darken($color-primary, 50%));
    color: $color-fg;
    font-family: "Varela Round", sans-serif;
}

button {
    background-color: transparent;
    color: $color-primary;
    border: solid 1px $color-primary;
    padding: 1% 2%;
    margin: 1% 0;

    &:hover {
        background-color: $color-subtle;
    }

    &:active {
        border-color: $color-secondary;
        color: $color-secondary;
    }
}

.iconSpan{
    :hover {
        color: $color-primary;
        cursor: pointer;
    }

    &.active {
        color: lighten($color-primary, 5%);
    }
}

#navbar {

    text-align: center;

    &.mobile {
        #mobileHeader {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: fixed;
            padding: 5vw;
            height: 8vh;
            width: 100vw;
            top:0;
            background-color: $color-bg;
            z-index: 999;

            h1 {
                font-size: 6vw;
            }
        }

        ul{
            display: flex;
            flex-direction: column;
            position: fixed;
            top:8vh;
            height: 92vh;
            background-color: $color-bg;
            width: 100vw;
            z-index: 999;

            li a {
                font-size: 5vw;
            }
        }
        
    }

    h1 {
        font-size: 3vw;
        user-select: none;
    }

    ul{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin: 0 auto;
        padding: 2% 0;
        list-style: none;

        &.hidden {
            display: none;
        }

        li {
            margin: 0 30px;

            &:not(.heading) {
                min-width: 12vw;
            }

            a {
                font-size: calc(2vw);
                color: $color-primary;
                text-decoration: none;
                transition: all 0.45s;
                width: fit-content;

                &:after {
                    content: "";
                    display: block;
                    margin: auto;
                    height: 3px;
                    width: 0px;
                    background: transparent;
                    transition: width 0.5s ease, background-color 0.5s ease;
                }
                &:hover:after {
                    width: 100%;
                    background: $color-primary;
                }

                &.active {
                    color: lighten($color-primary, 5%);
                    display: block;
                    width: 100%;
                    height: 100%;

                    &:after {
                        width: 100%;
                        background: lighten($color-primary, 5%);
                    }
                }
            }
        }
    }
}

#mobile-navbar {
    flex-direction: column;
}

#projectList {
    margin: 3% auto;
    height: 100%;
    text-align: center;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: auto;
    grid-gap: 5vh 3vw;


    @media only screen and (max-width : 1200px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (max-width : 768px) {
        grid-template-columns: repeat(1, 1fr);
    }


    & > div {
        box-shadow: inset 0 0 0 2px $color-secondary;
        box-sizing: border-box;
        padding: 2.5vh 1.5vw;
        user-select: none;
        position: relative;
        vertical-align: middle;

        &:before,
        &:after {
            border: 2px solid transparent;
            box-sizing: inherit;
            content: "";
            position: absolute;
            width: 0;
            height: 0;
        }

        &:before {
            top: 0;
            left: 0;
        }

        &:after {
            bottom: 0;
            right: 0;
        }

        &:hover {
            cursor: pointer;

            &:before,
            &:after {
                width: 100%;
                height: 100%;
            }

            &:before {
                border-top-color: $color-primary;
                border-right-color: $color-primary;
                transition: width 0.125s ease-out, height 0.125s ease-out 0.125s;
            }

            &:after {
                border-bottom-color: $color-primary;
                border-left-color: $color-primary;
                transition: border-color 0s ease-out 0.125s, width 0.125s ease-out 0.125s, height 0.125s ease-out 0.25s;
            }
        }

        &:active {
            background-color: lighten($color-bg, 5%);
        }

        div {
            height: fit-content;
            margin: auto;
            display: inline-block;
        }

        h1 {
            color: $color-primary;
            font-size: calc(30px + 0.3vw);
        }

        p {
            font-size: calc(15px + 0.3vw);
        }
    }
}

#LoginView {
    height: auto;
    width: auto;

    form {
        width: 30%;
        margin: auto;

        .form-group {
            margin: 2% 0;
            width: 100%;

            input {
                width: 100%;
                padding: 1%;
            }
        }
    }
}
